//
// Image
//

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.img-responsive {
    width: 60px;
    height: 40px;
}
